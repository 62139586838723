<template>
	<div style="padding: 10px;">
	  <h1 class="center-text full-top"><b>{{lang[this.appLang].download_p1}}</b></h1>
		<div class="center-text">
			<p class="half-top half-bottom">{{lang[this.appLang].download_p2}}</p>

			<a href="https://apps.apple.com/at/app/tapni/id1552930233" style="
			    background-image: url('https://cdn.tapni.co/images/TapniAppStore.png');
			    width: 250px;
			    display: inline-block;
			    height: 74px;
			    background-size: 100%;
				margin:15px 25px;
			"></a>

			<a href="https://play.google.com/store/apps/details?id=co.tapni.app" style="
			    background-image: url('https://cdn.tapni.co/images/TapniGooglePlay.png');
			    width: 250px;
			    display: inline-block;
			    height: 74px;
			    background-size: 100%;
				margin:15px 25px;
			"></a>

			<a href="https://appgallery.huawei.com/#/app/C103931997" style="
			    background-image: url('https://cdn.tapni.co/images/TapniGalleryApp.png');
			    width: 250px;
			    display: inline-block;
			    height: 74px;
			    background-size: 100%;
			    margin:15px 25px;
			"></a>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'DownloadApp',
  computed: {
  },
  methods: {
    init () {
      this.$store.commit('setInitialize', true)
      this.$store.commit('setLoading', false)
    }
  },
  mounted () {
    if (this.route) this.init()
  },
  watch: {
    'route.name' (nv, ov) {
      if (!ov && nv) this.init()
    },
  }
}
</script>

<style scoped>
</style>
