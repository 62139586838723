<template>
	<div style="padding: 10px;">
	  <h1 class="center-text full-top"><b>{{lang[this.appLang].not_found}}</b></h1>
	  <p class="center-text half-top">
		{{lang[this.appLang].my_qr}}
	    <br>
	    <br>
	    <router-link to="/welcome"
	                 style="z-index: 999; margin: 0;" class="button white-button">
		  {{lang[this.appLang].go_back}}
	    </router-link>
	  </p>
	</div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'NotFound',
  computed: {
  },
  methods: {
    init () {
      this.$store.commit('setInitialize', true)
      this.$store.commit('setLoading', false)
    }
  },
  mounted () {
    if (this.route) this.init()
  },
  watch: {
    'route.name' (nv, ov) {
      if (!ov && nv) this.init()
    },
  }
}
</script>

<style scoped>
</style>
